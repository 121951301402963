<template>
  <div class="dashboard">
    <van-nav-bar
      :title="title"
      fixed
      placeholder
    >
      <template #right>
        <van-icon
          :name="$icon('calendar')"
          :size="32"
          @click="showDatePicker = true"
        />
      </template>
    </van-nav-bar>
    <daily-summary
      v-if="diagnosis"
      :title="$t('summary.title')"
      :date="date"
      :nutrition-sums="nutritionSums"
      :nutrition-limits="nutritionLimits"
      :diagnosis="diagnosis"
      layout="compact"
    />
    <div class="meal-summary">
      <template v-for="(items, mealType) in lists">
        <food-list
          v-if="diagnosis"
          :key="mealType"
          :title="$t(`food.mealTypes.${mealType}`)"
          :meal-type="mealType"
          :items="items"
          :diagnosis="diagnosis"
        />
      </template>
    </div>
    <van-button
      :text="$t('dashboard.saveAsTemplate')"
      block
      type="plain"
      class="dashboard__save-template"
      @click="showTemplateForm = true"
    />
    <div class="branding__container">
      <branding />
    </div>
    <van-popup
      v-model="showDatePicker"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <van-nav-bar
        title="Monatsübersicht"
        :left-text="!isSameDay(date, new Date()) ? $t('today') : null"
        :right-text="$t('close')"
        fixed
        placeholder
        @click-left="setDateToday"
        @click-right="showDatePicker = false"
      />
      <calendar
        v-if="diagnosis"
        v-model="date"
        :date-selected="date"
        :diagnosis="diagnosis"
        show-legend
        @date="updateDate"
      />
    </van-popup>
    <van-popup
      v-model="showTemplateForm"
      position="bottom"
    >
      <div class="template-form">
        <van-form
          validate-first
          @submit="saveAsDayplan"
        >
          <field-list>
            <van-field
              v-model="templateTitle"
              type="text"
              :label="$t('template.form.label')"
              autofocus
            />
          </field-list>
          <van-button
            type="primary"
            native-type="submit"
            block
            round
          >
            {{ $t('template.form.submit') }}
          </van-button>
        </van-form>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { formatRelative, isSameDay, format } from 'date-fns';

import Dayplan from '@/models/dayplan';
import Diagnosis from '@/models/diagnosis';
import UserProfile from '@/models/user-profile';
import Calendar from '@/components/Calendar';
import DailySummary from '@/components/DailySummary';
import FieldList from '@/components/FieldList';
import FoodList from '@/components/FoodList';
import Branding from '@/components/Branding';
import { Notify } from 'vant';

import { auth, analytics } from '@/firebase';
import { dateLocale, getNutritionLimits } from '@/helper';

export default {
  components: {
    Calendar,
    DailySummary,
    FoodList,
    FieldList,
    Branding,
  },
  data() {
    return {
      userProfile: null,
      date: null,
      nutritionLimits: {},
      diagnosis: null,
      showDatePicker: false,
      showTemplateForm: false,
      templateTitle: '',
      foods: {
        breakfast: [],
        lunch: [],
        dinner: [],
        snack: [],
      },
      meals: {
        breakfast: [],
        lunch: [],
        dinner: [],
        snack: [],
      },
      mealItems: {
        breakfast: [],
        lunch: [],
        dinner: [],
        snack: [],
      },
    };
  },
  computed: {
    lists() {
      const foodsList = {};

      Object.keys(this.foods).forEach((key) => {
        const items = [...this.foods[key], ...this.mealItems[key]];
        items.sort((a, b) => a.added?.seconds - b.added?.seconds);
        foodsList[key] = items;
      });

      return foodsList;
    },

    diagnosisValues() {
      return {};
    },

    nutritionSums() {
      return this.$store.getters['dayplan/nutritionSums'];
    },

    title() {
      return this.date ? formatRelative(this.date, Date.now(), {
        locale: dateLocale,
      }) : null;
    },
  },

  watch: {
    meals: {
      deep: true,

      handler(mealTypes) {
        Object.entries(mealTypes).forEach((entry) => {
          const [mealType, docs] = entry;
          const items = [];

          docs.forEach((documentData) => {
            const item = {
              id: documentData.id,
              ...documentData.ref,
              ...documentData,
            };
            items.push(item);
          });

          this.mealItems[mealType] = items;
        });
      },
    },

    '$store.state.dayplan.selectedDate': {
      immediate: true,
      handler() {
        Dayplan.updateNutritionSums();
        Object.keys(this.lists).forEach((mealType) => {
          this.$bind(`foods.${mealType}`, Dayplan.getFoods(mealType));
          this.$bind(`meals.${mealType}`, Dayplan.getMeals(mealType), { wait: true });
        });
      },
    },

    // helps track nutrition limit changes from date change on calendar
    '$store.state.user.nutritionLimits': {
      immediate: true,
      handler() {
        this.nutritionLimits = this.$store.getters['user/nutritionLimits'];
      },
    },
  },

  async created() {
    this.userProfile = await new UserProfile(auth.currentUser.uid).get();
    this.date = this.$store.getters['dayplan/selectedDate'];
    this.diagnosis = await new Diagnosis(this.$store.getters['user/diagnosisName']);
    this.nutritionLimits = getNutritionLimits(this.userProfile, format(this.date, 'yyyy-MM-dd'));

    this.$store.commit('meal/reset');
  },

  methods: {
    updateDate(date) {
      this.date = date;
      this.showDatePicker = false;
      this.$store.commit('dayplan/selectDate', date);
    },

    setDateToday() {
      this.date = new Date();
      this.showDatePicker = false;
    },

    // Pass imported function into template.
    isSameDay,

    saveAsDayplan() {
      if (this.templateTitle === '') return;

      const data = {
        date: format(this.date, 'yyyy-MM-dd'),
        title: this.templateTitle,
      };

      this.$store.commit('template/addTemplate', data);
      this.showTemplateForm = false;

      Notify({
        type: 'success',
        message: this.$i18n.t('template.form.saved'),
        duration: 5000,
      });
      analytics.logEvent('template_create', {
        template_name: data.title,
      });
    },
  },
};
</script>

<style lang="scss">
@use '~@/styles/config' as config;

.dashboard__save-template {
  border: none;
  margin-bottom: 1.214em;
  background: transparent;
  font-size: config.$font-size-sm;
  font-weight: 600;
  line-height: 1.71;
  @media(min-width: 768px) {
    font-size: config.$font-size;
  }
}
.template-form {
  padding: 16px;
}
</style>
